/* https://docs.tappaysdk.com/tutorial/zh/web/front.html#tappay-fields-focus-style */
.tappay-field-focus {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.ant-btn {
  --antd-wave-shadow-color: rgba(0, 0, 0, 0.85);
}

.black-btn {
  background-color: rgba(0, 0, 0, 0.85);
  border-color: rgba(0, 0, 0, 0.85);
  color: #fff;
}

.black-btn:hover,
.black-btn:focus {
  background-color: rgba(0, 0, 0, 0.85);
  border-color: rgba(0, 0, 0, 0.85);
  color: #fff;
}

.black-btn .ant-typography,
.black-btn .anticon {
  color: #fff;
}

.black-btn[disabled] .ant-typography,
.black-btn[disabled] .anticon {
  color: rgba(0, 0, 0, 0.25);
}

.terms-btn span {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  text-decoration: underline;
}

.subscription-plan-card {
  padding: 16px;
  border: 1px solid #d9d9d9;
  width: 100%;
  box-sizing: border-box;
}

.subscription-plan-card:hover {
  border: 1px solid #1677ff;
  cursor: pointer;
}

.subscription-plan-price-radio.ant-radio-button-wrapper::before {
  display: none;
}
